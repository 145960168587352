// app-home {
//   overflow-x: hidden;
//   margin: 0px;
//   color: white;
//   margin: 0;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
// }

body{
  // overflow-y: hidden;
  scroll-behavior: smooth!important;
  overflow-x: hidden;
  margin: 0px;
  color: white;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2B2D42;
}