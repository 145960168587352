@font-face {
  font-family: "Roboto-Black";
  src: url("/assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
* {
  font-family: "Roboto-Regular";
  line-height: 1;
  color: #2B2D42;
}

h1 {
  font-family: "Roboto-Black";
  line-height: 1;
  text-align: right;
  margin: 0;
  padding: 0;
  font-size: 9rem;
  color: white;
}
@media (max-width: 750px) {
  h1 {
    font-size: 12vw;
  }
}

h2 {
  font-size: 2rem;
  color: #8D99AE;
}
@media (max-width: 750px) {
  h2 {
    font-size: 3.6vw;
  }
}

h2, h3, h4, h5, p {
  font-family: "Roboto-Regular";
  font-weight: 600;
  line-height: 1;
  margin: 0;
  padding: 0;
}

p {
  font-size: 0.8rem;
  text-align: left;
  color: #2B2D42;
}

body {
  scroll-behavior: smooth !important;
  overflow-x: hidden;
  margin: 0px;
  color: white;
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2B2D42;
}